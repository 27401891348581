@import "mixins"
.footer
  padding: 0 8px calc(var(--menuHeight) + 20px)
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.3)
  border-top: 2px solid rgb(var(--particularColor))
  overflow: hidden
  margin-top: 30px

.nav
  display: flex
  flex-wrap: wrap
  margin-top: 18px

  > div
    width: 50%
    @include flex
    flex-direction: column
    margin-bottom: 16px

    > h2
      color: #fff
      margin-bottom: 12px

  a
    color: rgb(var(--primaryColor))
    padding: 8px 0
    text-align: center

.footerCrypto
  display: flex
  justify-content: space-around
  flex-wrap: wrap
  border-top: 1px solid var(--footer_border)
  box-shadow: inset 0 1px var(--footer_shadow)
  padding: 6px 0

  > span
    margin: 2px 5px !important

    svg
      path
        fill: var(--svgIcon_fill)

.footerLogo
  border-top: 1px solid var(--footer_border)
  box-shadow: inset 0 1px var(--footer_shadow)
  @include flex
  justify-content: center
  flex-direction: column
  padding: 12px 0

  h1
    margin-top: 10px
    text-align: center
    color: #fff

  > *
    vertical-align: top

.footerSite
  @include flex
  height: 45px
  border-top: 1px solid var(--footer_border)
  border-bottom: 1px solid var(--footer_shadow)
  box-shadow: inset 0 1px var(--footer_shadow), inset 0 -1px var(--footer_border)
  position: relative
  padding: 0 10px
  justify-content: center

.socialLinks
  height: 48px
  padding-top: 2px
  @include flex
  justify-content: center
  border-top: 1px solid var(--footer_border)
  box-shadow: inset 0 1px var(--footer_shadow)
  .age
    margin-right: 10px

.age
  @include flex
  justify-content: center
  color: var(--txt_color)
  width: 30px
  height: 30px
  padding-top: 2px
  border-radius: 100%
  background: var(--footer_ageBg)
  @include bold
  font-size: 13px
  line-height: 10px

.beGambleAware
  border-top: 1px solid var(--footer_border)
  box-shadow: inset 0 1px var(--footer_shadow)
  @include flex
  flex-direction: column
  justify-content: center
  padding: 12px 0
  .age
    margin-top: 10px
    border: 2px solid var(--footer_ageBg)
    background-color: transparent
    color: #fff

.version
  padding: 8px 0 0
  text-align: center
  font-size: 11px
  color: var(--footer_versionColor)

.footerText
  @include flex
  flex-direction: column
  border-top: 1px solid var(--footer_border)
  box-shadow: inset 0 1px var(--footer_shadow)
  padding: 16px 20px 16px 10px
  color: rgb(var(--base_textPrimaryColor))

  .txt
    line-height: 18px
